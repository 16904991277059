import Vue from 'vue'
import carPic from '@/assets/images/car.svg'
import coalCarPic from '@/assets/images/car.png'


if (!TMap.HMap) {
    Object.defineProperty(TMap, 'HMap', {
        get() {
            return HMap
        }
    })
}

/**
 *  initCenter Array 初始化中心点
 *  maxZoom Number 最大缩放级别
 *  zoom 初始化缩放级别
 */
function HMap(mapId, options = {}) {
    this.mapId = mapId
    this.initCenter = options.initCenter || [39.271139, 110.329849]
    this.maxZoom = options.maxZoom || 16
    this.initZoom = options.initZoom || 16
    this.map = null
    this.carLayer = null
    this.infoWindows = {} // 存放infoWindows
    this.initMap()
    HMap['HMapInstance-' + mapId] = this
}

// 初始化地图
HMap.prototype.initMap = function () {
    this.map = new TMap.Map(this.mapId, {
        center: new TMap.LatLng(...this.initCenter), //设置地图中心点坐标
        zoom: this.initZoom,
        maxZoom: this.maxZoom,
        viewMode: '2D',
        showControl: false,
        baseMap: {  // 设置卫星地图
            type: 'satellite'
        }
    })

}

// 初始化一个图层，用来放定位数据(车子图片)
HMap.prototype.initCarLayer = function () {
    var markerLayer = new TMap.MultiMarker({
        map: this.map, //指定地图容器
        //样式定义
        styles: {
            //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
            myStyle: new TMap.MarkerStyle({
                width: 30, // 点标记样式宽度（像素）
                height: 30, // 点标记样式高度（像素）
                src: carPic, //图片路径
                //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
                anchor: { x: 15, y: 15 }
            }),
            coalCarPic: new TMap.MarkerStyle({
                width: 30, // 点标记样式宽度（像素）
                height: 30, // 点标记样式高度（像素）
                src: coalCarPic, //图片路径
                //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
                anchor: { x: 15, y: 15 }
            }),
        },
        //点标记数据数组
        geometries: []
    })
    this.carLayer = markerLayer
}

// 初始化一个图层，用来放不变的一个路径
HMap.prototype.initPathLayer = function () {
    let paths = [[39.271454604576164, 110.3333035087328],
    [39.27087322661866, 110.33356098737409],
    [39.270341676721046, 110.33317476924844],
    [39.270059289343, 110.33178009193784],
    [39.27020878876316, 110.33124367747428],
    [39.270590841240065, 110.3284328658358],
    [39.27658138615566, 110.32245957186706],
    [39.27651840238589, 110.32084031901127],
    [39.276950248834105, 110.31884485765863],
    [39.276917030175774, 110.31787931218082],
    [39.27543877471467, 110.31524015209675],
    [39.27525606681673, 110.31521869638914],
    [39.2738940477057, 110.31511141326723],
    [39.27279776900203, 110.30891046165584],
    [39.27337913103952, 110.30560614909723],
    [39.273329040771465, 110.3047946922261],
    [39.27369527502796, 110.30365684911851],
    [39.27379491836617, 110.30215515553175],
    ];
    paths = paths.map(i => {
        return new TMap.LatLng(i[0], i[1])
    })

    new TMap.MultiPolyline({
        id: 'green-path-layer', //图层唯一标识
        map: this.map,
        styles: {
            blueLine: new TMap.PolylineStyle({
                color: 'rgba(109,233,73,0.5)', //线填充色
                width: 16, //折线宽度
                borderWidth: 0, //边线宽度
                borderColor: '#FFF', //边线颜色
                lineCap: 'butt', //线端头方式
                showArrow: false
            })
        },
        geometries: [
            {
                id: 'green-path',
                styleId: 'blueLine',
                paths: paths
            }
        ]
    })
}

/**
 *  
 * @param {车子的为唯一id} clientId 
 * @returns false 表示车子之前已经加载过了
 *          true  表示车子之前没有加载过，需要去渲染车子以及infoWindow
 */
HMap.prototype.setInfoWindow = function (clientId) {
    if (this.infoWindows.hasOwnProperty(clientId)) return false
    let infoWindow = new TMap.InfoWindow({
        content: '<div></div>',
        map: this.map,
        position: new TMap.LatLng(39.91992, 116.427648),
        offset: { x: 0, y: -15 },
        enableCustom: true
    })
    infoWindow.close()
    this.infoWindows[clientId] = infoWindow
    return true
}

HMap.prototype.getInfoWindow = function (clientId) {
    return this.infoWindows[clientId]
}

/**
 *  更新位置和infoWindow
 */
HMap.prototype.updateCarInfo = function (item) {
    if (item.latitude && item.longitude && item.latitude != 0 && item.longitude != 0) {
        let position = new TMap.LatLng(...HMap.wgs84Togcj02(item.latitude / 10000000, item.longitude / 10000000))
        let styleId;
        if (item.plateNo == '/') {
            // 拉土车
            styleId = 'myStyle'
        } else {
            styleId = 'coalCarPic'
        }
        this.carLayer.updateGeometries([
            {
                id: item.clientId,
                styleId: styleId,
                position: position
                // properties: {
                //   plateNo: item.plateNo, // 车牌
                //   isUp: item.isUp // 是否在线 0不在线 1在线
                // }
            }
        ])
        let infowindow = this.infoWindows[item.clientId]
        let svg =
            '<svg t="1661935953433" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2798" width="20" height="20"><path d="M828.48 854.72a32 32 0 1 1-44.8-45.44A378.24 378.24 0 0 0 896 540.48a384 384 0 0 0-768 0 378.24 378.24 0 0 0 112.32 268.8 32 32 0 0 1 0 45.12 32 32 0 0 1-45.44 0A443.84 443.84 0 0 1 512 96a448 448 0 0 1 448 444.48 441.6 441.6 0 0 1-131.52 314.24z" p-id="2799" fill="#ffffff"></path><path d="M96 608a32 32 0 0 1 0-64h64a32 32 0 0 1 0 64z m768 0a32 32 0 0 1 0-64h64a32 32 0 0 1 0 64zM201.28 278.72a32 32 0 1 1 45.44-45.44l32 32a32 32 0 0 1-45.44 45.44z m544 544a32 32 0 0 1 45.44-45.44l32 32a32 32 0 0 1-45.44 45.44z m32-589.44a32 32 0 1 1 45.44 45.44l-32 32a32 32 0 0 1-45.44-45.44z m-544 544a32 32 0 0 1 45.44 45.44l-32 32a32 32 0 0 1-45.44-45.44zM480 160a32 32 0 0 1 64 0v64a32 32 0 0 1-64 0z m117.76 345.92l-32 8.64a459.2 459.2 0 0 0-68.8 22.08 41.92 41.92 0 0 0-12.16 53.44 28.48 28.48 0 0 0 41.28 13.44 662.72 662.72 0 0 0 70.08-75.2l7.68-8.64 16-19.2z m-48.64-53.44l32-8.64c25.28-6.4 52.16-12.8 79.04-18.88l25.92-6.08 10.24-2.24a32 32 0 0 1 32 51.2c0 1.6-3.2 4.48-6.4 8l-16.96 21.12c-17.92 21.76-35.84 43.52-53.12 64l-7.68 8.96a542.08 542.08 0 0 1-84.48 87.68 92.48 92.48 0 0 1-132.16-37.76 105.92 105.92 0 0 1 34.56-137.28 387.84 387.84 0 0 1 87.04-30.08z" p-id="2800" fill="#ffffff"></path></svg>'
        let content = `<div class="my-overlay">
                        <div class="${item.isUp == 0 ? 'off' : 'on'}"></div>
                       <div>${item.clientNo}</div>
                       <div style="display:flex;align-items:center;justify-content:space-between">
                        ${svg}
                        <text>${item.speed ? item.speed / 10 : 0}km/h</text> 
                      </div>
                  </div>`;
        infowindow && infowindow.setContent(content)
        infowindow && infowindow.setPosition(position)
        infowindow && infowindow.open()
    }
}

/**
 *  切换底图方法
 */
HMap.prototype.changeBaseMap = function (type) {
    const types = {
        1: 'vector',
        2: 'satellite'
    }
    type = types[type]
    let map = this.map
    if (map && type) {
        map.setBaseMap({ type });
    }
}


/**
 * 坐标转换
 */
//定义一些常量
const PI = 3.1415926535897932384626;
const a = 6378245.0;
const ee = 0.00669342162296594323;

function out_of_china(lat, lng) {
    return (lng < 72.004 || lng > 137.8347) || ((lat < 0.8293 || lat > 55.8271) || false);
}

function transformlat(lat, lng) {
    let ret = -100.0 + 2.0 * lng + 3.0 * lat + 0.2 * lat * lat + 0.1 * lng * lat + 0.2 * Math.sqrt(Math.abs(lng))
    ret += (20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0 / 3.0
    ret += (20.0 * Math.sin(lat * PI) + 40.0 * Math.sin(lat / 3.0 * PI)) * 2.0 / 3.0
    ret += (160.0 * Math.sin(lat / 12.0 * PI) + 320 * Math.sin(lat * PI / 30.0)) * 2.0 / 3.0
    return ret

}
function transformlng(lat, lng) {
    let ret = 300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng))
    ret += (20.0 * Math.sin(6.0 * lng * PI) + 20.0 *
        Math.sin(2.0 * lng * PI)) * 2.0 / 3.0
    ret += (20.0 * Math.sin(lng * PI) + 40.0 *
        Math.sin(lng / 3.0 * PI)) * 2.0 / 3.0
    ret += (150.0 * Math.sin(lng / 12.0 * PI) + 300.0 *
        Math.sin(lng / 30.0 * PI)) * 2.0 / 3.0
    return ret
}

/**
 * 将wgs84坐标转成腾讯坐标
 */
HMap.wgs84Togcj02 = function (lat, lng) {
    if (out_of_china(lat, lng)) {
        return [lat, lng]
    }
    let dlat = transformlat(lat - 35.0, lng - 105.0);
    let dlng = transformlng(lat - 35.0, lng - 105.0);
    let radlat = lat / 180.0 * PI;
    let magic = Math.sin(radlat);
    magic = 1 - ee * magic * magic;
    let sqrtmagic = Math.sqrt(magic);
    dlat = (dlat * 180.0) / ((a * (1 - ee)) / (magic * sqrtmagic) * PI);
    dlng = (dlng * 180.0) / (a / sqrtmagic * Math.cos(radlat) * PI);
    var mglat = lat + dlat;
    var mglng = lng + dlng;
    return [mglat, mglng]
}

/**
 * 将腾讯坐标转成wgs84坐标
 */
HMap.gcj02Towgs84 = function (lat, lng) {
    if (out_of_china(lat, lng)) {
        return [lat, lng]
    }
    else {
        var dlat = transformlat(lat - 35.0, lng - 105.0);
        var dlng = transformlng(lat - 35.0, lng - 105.0);
        var radlat = lat / 180.0 * PI;
        var magic = Math.sin(radlat);
        magic = 1 - ee * magic * magic;
        var sqrtmagic = Math.sqrt(magic);
        dlat = (dlat * 180.0) / ((a * (1 - ee)) / (magic * sqrtmagic) * PI);
        dlng = (dlng * 180.0) / (a / sqrtmagic * Math.cos(radlat) * PI);
        var mglat = lat + dlat;
        var mglng = lng + dlng;
        return [lat * 2 - mglat, lng * 2 - mglng]
    }
}

