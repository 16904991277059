<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app'
}
</script>

<style lang='less'>
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}
#app {
  height: 100%;
  overflow: hidden;
}

/* 自定义overlay的样式 */
.my-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  width: 110px;
  font-size: 12px;
  color: #fff;
  padding: 4px 6px;
  > .on {
    position: absolute;
    top: 8x;
    right: 10px;
    width: 10px;
    height: 10px;
    background-color: #00ff2f;
    // box-shadow: 1px 1px 4px #4caf50;
    border-radius: 50%;
  }
  > .off {
    position: absolute;
    top: 8px;
    right: 10px;
    width: 10px;
    height: 10px;
    background-color: #ff0000;
    // box-shadow: 1px 1px 4px #e64a19;
    border-radius: 50%;
  }
}

.transTable {
  //   background-color: transparent;
  background-color: rgba(0, 0, 0, 0.4) !important;
  border: none !important;
  border-left: 1px solid #b1b1b1 !important;
  border-top: 1px solid #b1b1b1 !important;
  &::before,
  &::after {
    background-color: transparent !important;
  }
  tr,
  th,
  td {
    background-color: transparent !important;
    color: #fff;
    border-right: 1px solid #b1b1b1 !important;
    border-bottom: 1px solid #b1b1b1 !important;
  }
  .el-button--mini {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  // th:last-child,
  // td:last-child {
  //   background-color: rgba(0, 0, 0, 0.7) !important;
  // }
}

::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar-thumb {
  display: none;
}

:hover::-webkit-scrollbar {
  display: block;
  width: 6px;
  height: 6px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 6px;
}
//滚动条的滑块
:hover::-webkit-scrollbar-thumb {
  display: block;
  background-color: #27479d;
  border-radius: 6px;
}
</style>
